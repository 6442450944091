// Navbar (Based on 'vendor/stickyNavbar')
// ==================================================

// Sticky navbar (check)

function check_sticky() {
	if ($(".js_navbar").hasClass("unsticky")) {
		$(".js_navbar").removeClass("animated")
		$(".js_navbar").removeClass("fadeInDown")
	}
}
window.setInterval(check_sticky, 750);

// Navbar sticky

$(function () {
	$('.js_navbar').stickyNavbar({
		activeClass: "active",
		sectionSelector: "js_scrollto",
		animDuration: 800,
		startAt: 0,
		easing: "swing",
		animateCSS: true,
		selector: "a",
		mobile: true,
		mobileWidth: 480,
		zindex: 9999,
		stickyModeClass: "sticky",
		unstickyModeClass: "unsticky"
	});
});

// Navbar show only on scroll up

$(document).ready(function() {
	var previousScroll = 0,
	headerOrgOffset = $('.navbar').offset().top;

	$(window).scroll(function() {
			var currentScroll = $(this).scrollTop();
			if(currentScroll > headerOrgOffset) {
					if (currentScroll > previousScroll) {
							$('.navbar').css('transform', 'translateY(-85px)');
					} else {
							$('.navbar').css('transform', 'translateY(0)');
					}
			} else {
			}
			previousScroll = currentScroll;
	});
	
});